<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.title"
        allow-clear
        label="搜索帖子"
        v-bind="layout"
        placeholder="请输入帖子标题"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.channel_id"
        v-bind="layout"
        key-field="id"
        label="所属频道"
        inner-search
        placeholder="全部频道"
        value-field="id"
        label-field="name"
        show-all
        :options="channelData"
      />
      <BjInput
        v-model="search.nickname"
        allow-clear
        label="搜索用户"
        v-bind="layout"
        placeholder="请输入用户昵称"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.post_type"
        v-bind="layout"
        key-field="id"
        label="帖子类型"
        inner-search
        placeholder="全部类型"
        value-field="id"
        label-field="name"
        show-all
        :options="postData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="帖子状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="stateData"
      />
    </BjSearch>
    <div :class="$style.container">
      <a-row :class="$style.header">
        <a-col span="4" :class="$style.title"> 帖子列表 </a-col>
        <a-col span="20" :class="$style.btns">
          <div :class="[$style.badge, recycleTotal === 0 ? $style.badgeno : null]">
            <dustbin v-permission="'app.community.post-recycle.view'" :count="recycleTotal" @refresh="refresh" />
          </div>
          <!-- <displayMode v-model="active" :class="$style.mode" /> -->
        </a-col>
      </a-row>
      <Box v-if="active" />
      <Table v-else :loading="loading" :data="data" @detail="onDetail" @refresh="refresh" />
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
      <bj-modal
        title="帖子详情"
        :visible="visible.detail"
        :body-style="{ height: '400px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <BjForm :class="$style.detail">
          <a-row>
            <a-col span="6">
              <span class="required">*</span>
              帖子状态
            </a-col>
            <a-col span="18">
              <a-radio-group v-model="detail.status" :disabled="detailData.status !== 1">
                <a-radio :value="2"> 审核通过 </a-radio>
                <a-radio :value="3"> 审核驳回 </a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <div :class="$style.line" />
          <a-carousel v-if="detail.post_type === 'image'" autoplay>
            <div v-for="(item, index) in detail.image" :key="index" :class="$style.carousel">
              <img :src="item" />
            </div>
          </a-carousel>
          <video v-if="detail.post_type === 'video'" :class="$style.video" controls="controls" :src="detail.video" />
          <p :class="$style.content">
            {{ detail.content }}
          </p>
        </BjForm>
      </bj-modal>
    </div>
  </div>
</template>

<script>
// import displayMode from '@/components/displayMode'
import { communityService } from '@/service'

import Box from './Box.vue'
import dustbin from './dustbin.vue'
import Table from './Table.vue'

const service = new communityService()

export default {
  name: 'Home',
  components: {
    dustbin,
    // displayMode,
    Table,
    Box,
  },
  data() {
    return {
      search: {
        title: null,
        channel_id: '',
        post_type: '',
        nickname: '',
        status: 0,
      },
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      active: false,
      // data: [],
      channelData: [],
      recycleTotal: 0,
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      postData: [
        { name: '全部类型', id: '' },
        {
          name: '文字',
          id: 'text',
        },
        {
          name: '图文',
          id: 'image',
        },
        {
          name: '视频',
          id: 'video',
        },
      ],
      stateData: [
        { name: '全部状态', id: 0 },
        {
          name: '待审核',
          id: 1,
        },
        {
          name: '审核通过',
          id: 2,
        },
        {
          name: '审核失败',
          id: 3,
        },
      ],
      visible: {
        detail: false,
      },
      detail: {}, // 详情
      detailData: {}, // 原本数据
    }
  },
  created() {
    this.getChannel()
    this.getList()
    this.getRecycleTotal()
  },
  methods: {
    async getRecycleTotal() {
      try {
        const { data } = await service.recycleTotal()
        this.recycleTotal = data
      } catch (e) {}
    },
    async getChannel() {
      try {
        const { data } = await service.getChannelList({
          no_page: 1,
        })
        this.channelData = [{ name: '全部频道', id: '' }].concat(data)
      } catch (e) {}
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getPostList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
        })
        this.total = data.total
        this.data = data.record
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    refresh() {
      this.page = 1
      this.getList()
      this.getRecycleTotal()
    },
    async onDetail(item) {
      try {
        const { data } = await service.getPostInfo({
          id: item.id,
        })
        this.detail = data
        this.detailData = item
      } catch (e) {}
      this.visible.detail = true
    },
    init() {
      this.search = {
        title: null,
        channel_id: '',
        post_type: '',
        nickname: '',
        status: 0,
      }
      this.onSearch()
    },
    async handleOk() {
      if (this.detailData.status !== 1) {
        this.visible.detail = false
        return
      }
      try {
        await service.examinePost({
          id: this.detail.id,
          status: this.detail.status,
        })
        this.$message.success('审核成功')
        this.visible.detail = false
        this.getList()
      } catch (e) {}
    },
    handleCancel() {
      this.visible.detail = false
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .container {
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .title {
      color: #000;
      font-size: 16px;
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .mode {
        width: 93px;
        height: 20px;
        margin: 0 20px;
        text-align: right;
        border-left: 1px solid #eee;

        i {
          position: relative;
          top: -5px;
        }
      }

      .badge {
        width: 100px;
        height: 30px;
        color: #000;
        line-height: 30px;
        cursor: pointer;

        i {
          position: relative;
          top: 2px;
          left: -2px;
          color: #000;
          font-size: 20px;
        }
      }

      .badgeno {
        width: 70px;
      }
    }
  }

  .header {
    padding: 0 0 20px 0;
  }
}

.detail {
  .line {
    width: 100%;
    margin: 20px 0;
    border-top: 1px solid #eee;
  }

  .content {
    margin-top: 10px;
    margin-bottom: 20px;
    white-space: pre-wrap;
  }

  .video {
    width: 100%;
  }

  .carousel {
    img {
      width: 480px;
      height: 240px;
      object-fit: cover;
    }
  }
}
</style>
