<template>
  <div :class="$style.table">
    <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
      <template #cover="item">
        <div :class="$style.cover">
          <div :class="$style.img">
            <img :src="item.cover" />
            <div :class="$style.type">
              <i v-if="item.post_type === 'text'" class="ri-article-line Copy" />
              <i v-if="item.post_type === 'image'" class="ri-image-line" />
              <i v-if="item.post_type === 'video'" class="ri-play-circle-line" />
            </div>
          </div>
          <div :class="$style.title" class="ellipsis2">{{ item.title }}</div>
        </div>
      </template>
      <template #nick="item">
        <div :class="$style.nick">
          <img :src="item.user.avatar" />
          <div>{{ item.user.nickname }}</div>
        </div>
      </template>
      <template #status="item">
        <BjTag :type="item.status === 2 ? 'primary' : 'default'">{{ item.status | stateName }}</BjTag>
      </template>
      <template #action="item">
        <bj-link v-permission="'app.community.post-detail'" type="primary" @click="onEdit(item)"> 详情 </bj-link>
        <a-popconfirm placement="topLeft" title="确定要删除帖子吗？" @confirm="onDelete(item)">
          <bj-link v-permission="'app.community.post-delete'" type="danger"> 删除 </bj-link>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
</template>

<script>
import { communityService } from '@/service'

const service = new communityService()

export default {
  name: 'Home',
  filters: {
    stateName(val) {
      switch (val) {
        case 1:
          return '待审核'
        case 2:
          return '已通过'
        case 3:
          return '未通过'
      }
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    columns() {
      return [
        {
          title: '帖子',
          width: 200,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '发布用户',
          scopedSlots: {
            customRender: 'nick',
          },
        },
        {
          title: '所属频道',
          customRender: item => item.channel_name || '-',
        },
        {
          title: '评论数量',
          customRender: item => item.comment_num || 0,
        },
        { title: '发布时间', dataIndex: 'created_at' },
        {
          title: '帖子状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          width: 180,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  methods: {
    onEdit(item) {
      this.$emit('detail', item)
    },
    async onDelete(item) {
      try {
        await service.deletePost({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.$emit('refresh')
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.table {
  .cover {
    display: flex;

    .img {
      position: relative;
      width: 64px;
      height: 64px;
      overflow: hidden;
      border-radius: 6px;

      img {
        width: 64px;
        height: 64px;
        object-fit: cover;
      }

      .type {
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 6px 0 6px 0;

        i {
          color: #fff;
          font-size: 14px;
        }
      }
    }

    .title {
      flex: 1;
      max-width: 144px;
      margin-left: 10px;
      color: #000;
      font-size: 14px;
    }
  }

  .nick {
    display: flex;
    align-items: center;
    color: @primary-color;

    img {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      border-radius: 100px;
    }
  }
}
</style>
